import {createContext, useContext, useEffect, useState} from "react";
import api from "../services/api";
import {professionLabels, USER_TYPE, USER_TYPE_LABEL, USER_TYPE_LABEL_FROGGED} from "../constants/userTypes";
import {getImageUrl} from "../components/util";
import React from 'react';
import {isTokenExpired} from "../components/Token";
import {useParams, useNavigate, useLocation} from "react-router";

const defaultValue = {
    loading: true,
    user: {}
}

export const AuthContext = createContext(defaultValue)

export const AuthProvider = (props) => {
    const [loading, setLoading] = useState(defaultValue.loading)
    const [user, setUser] = useState(defaultValue.user)
    const [otherProps, setOtherProps] = useState({})
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        fetchUser()
    }, [])

    const logout = () => {
        let ip = '';
        ip = localStorage.getItem("ip");
        localStorage.clear();
        localStorage.setItem('ip', ip);
        navigate('/login');
        setOtherProps({})
        setUser({})
    }

    const fetchUser = () => {
        const tokenValue = localStorage.getItem('token');
        const loggedInValue = localStorage.getItem('loggedIn');

        setOtherProps(prevState => ({...prevState, tokenValue: tokenValue, loggedInValue: loggedInValue}));

        if (tokenValue) {
            if (isTokenExpired()) {
                return logout()
            }
            setLoading(true)

            api.get('user/header', null, true)
                .then(res => {

                    if (res.status === '0') {
                        if (res.user_type_id === 1) {
                            navigate('/setup')
                        } else if (res.user_type_id === 3) {
                            navigate('/setup-for-intermediaries')
                        }
                    }

                    api.get('user/saved_search', null, true)
                        .then(search => {
                            if (!search.hadSavedSearch) {
                                if (res.user_type_id === USER_TYPE.BUYER) {
                                    navigate('/setup')
                                }
                            }

                        });

                    if (res.status === '2') {
                        localStorage.clear();
                        navigate('/home');
                    }

                    if (res.premium) {
                        setOtherProps(prevState => ({
                            ...prevState,
                            ...(res.premium_obj.due_date ? { premiumUntil: res.premium_obj.due_date } : {}),
                            ...(res.premium_obj.is_contract ? { underContract: res.premium_obj.is_contract } : {}),
                        }));
                    }

                    if (!res.linkedin_url && (res.user_type_id === USER_TYPE.BUYER || res.user_type_id === USER_TYPE.PROVIDER)) {
                        setOtherProps(prevState => ({
                            ...prevState,
                            linkedinModal: true
                        }));
                    }
                    setUser(res)
                    setOtherProps(prevState => ({
                        ...prevState,
                        user_type_id: res.user_type_id,
                        profile_pic: (res.profile_pic) ? (res.profile_pic.indexOf('base64') > -1) ? `${res.profile_pic}` : (res.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(res.profile_pic) : getImageUrl(res.profile_pic) : '/images/thumbnail-small.gif',
                        id: res.id,
                        unread: res.unread,
                        headerAd: res.headerAds.length ? res.headerAds[Math.floor(Math.random() * res.headerAds.length)] : null,
                        free_trial_end: res.free_trial_end,
                        role: res.admin_id,
                        isAdmin: Boolean(res.admin_id),
                    }))

                    api.get(`user/track`, null, true);

                    api.get('messages/free', null, true)
                        .then(res => {
                            updateOtherProps({
                                free: res.free,
                            });
                        });

                    api.get('promote/credits', null, true)
                        .then(credits => {
                            updateOtherProps({
                                featured_profile_credits: credits.featured_profile,
                                featured_listing_credits: credits.featured_listing,
                            });
                        });
                })
                .finally(() => {
                    setLoading(false)
                });

        } else {

            setLoading(false)
        }

    }

    const updateOtherProps = data => {
        setOtherProps(prevState => ({
            ...prevState,
            ...data,
        }))
    }

    const value = {
        ...otherProps,
        loading,
        user,
        updateOtherProps,
        location,
        navigate,
        params,
        actions: {
            logout,
            fetchUser
        }
    }
    return (<AuthContext.Provider value={value}>
        {props.children}
    </AuthContext.Provider>)
}

export const AuthConsumer = AuthContext.Consumer


export const useAuthContext = () => {
    return useContext(AuthContext)
}

